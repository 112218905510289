import React from 'react';
import './App.css';

import './template/assets/css/bootstrap.min.css';
import './template/assets/css/now-ui-kit.css';
import './template/assets/css/demo.css';

import logo from './images/logo.png';
import social from './images/chill.png';
import muse from './images/muse.png';

function App() {
  return (<div className="about-us">
    <div className="wrapper">
      <div className="page-header clear-filter">
        <div className="page-header-image" data-parallax="true" style={{"transform": "translate3d(0px, 0px, 0px);"}}>
          <div className="rellax-header rellax-header-sky" data-rellax-speed="-8">
                <div className="page-header-image background-image-a">
                </div>
            </div>
        </div>
        <div className="content-center">
          <div className="row">
            <div className="col-md-8 ml-auto mr-auto">
              <img src={logo} />
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="about-description text-center">
          <div className="features-3">
            <div className="container">
              <div className="row">
                <div className="col-md-8 mr-auto ml-auto">
                  <h2 className="title">Simpler. Smarter. Faster.</h2>
                  <h4 className="description">PTR's Data Science Platform offers comprehensive insights and analytics to understand consumer spending behaviors, enabling businesses to optimize marketing strategies, enhance customer engagement, and drive revenue growth through targeted data-driven decisions.</h4>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="info info-hover">
                    <div className="icon icon-success icon-circle">
                      <i className="now-ui-icons objects_globe"></i>
                    </div>
                    <h4 className="info-title">Asset Management</h4>
                    <p className="description">Cross-POS dashboards provide sophisticated reporting for enterprise.</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="info info-hover">
                    <div className="icon icon-info icon-circle">
                      <i className="now-ui-icons education_atom"></i>
                    </div>
                    <h4 className="info-title">Resource Utilization</h4>
                    <p className="description">JPTR offers concise summaries and detailed analytics on consumer spending trends.</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="info info-hover">
                    <div className="icon icon-primary icon-circle">
                      <i className="now-ui-icons tech_watch-time"></i>
                    </div>
                    <h4 className="info-title">Self-Healing</h4>
                    <p className="description">JPTR helps clients economize, streamline processes, and reduce workload.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="separator-line separator-primary"></div>
        <div className="projects-5">
          <div className="container">
            <div className="row">
              <div className="col-md-8 ml-auto mr-auto text-center">
                <h2 className="title">We have many advantages</h2>
                <h4 className="description">JPTR excels in analyzing consumer spending behavior, providing businesses with crucial insights into market trends. Our data-driven approach enhances marketing strategies and product development, directly impacting customer engagement and business growth. We continuously innovate, ensuring our clients receive the most relevant insights to stay ahead in their markets.</h4>
                <div className="section-space"></div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-5 ml-auto">
                <div className="card card-background card-background-social card-raised">
                  <div className="card-body">
                    <h2 className="card-title">Social Analytics</h2>
                    <p className="card-description">
                    JPTR offers insights to craft, engage, and convert. Grasp your audience's preferences, impact, interactions, and goals. Identify new trends and influencers to capture fresh audiences.</p>
                    <label className="badge badge-neutral">Analytics</label>
                  </div>
                </div>
              </div>
              <div className="col-md-5 mr-auto">
                <div className="info info-horizontal">
                  <div className="icon icon-danger">
                    <i className="now-ui-icons ui-2_chat-round"></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title">Listen to Social Conversations</h4>
                    <p className="description">
                      Gain access to the demographics, psychographics, and location of unique people who talk about your brand.
                    </p>
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon icon-danger">
                    <i className="now-ui-icons business_chart-pie-36"></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title">Performance Analyze</h4>
                    <p className="description">
                      Unify data from Facebook, Instagram, Twitter, LinkedIn, and Youtube to gain rich insights from easy-to-use reports.
                    </p>
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon icon-danger">
                    <i className="now-ui-icons design-2_ruler-pencil"></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title">Social Conversions</h4>
                    <p className="description">
                      Track actions taken on your website that originated from social, and understand the impact on your bottom line.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-md-5 ml-auto mt-5">
                <div className="info info-horizontal">
                  <div className="icon icon-warning">
                    <i className="now-ui-icons users_single-02"></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title">Work With Any Team</h4>
                    <p className="description">
                      Whether it’s for work or even the next family vacation, JPTR helps your team.
                    </p>
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon icon-warning">
                    <i className="now-ui-icons business_chart-bar-32"></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title">A Productivity Platform</h4>
                    <p className="description">
                      Integrate the apps your team already uses directly into your workflow.
                    </p>
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon icon-warning">
                    <i className="now-ui-icons arrows-1_refresh-69"></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title">Always In Sync</h4>
                    <p className="description">
                      No matter where you are, JPTR stays in sync across all of your devices.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-5 mr-auto">
                <div className="card card-background card-background-shop card-raised">
                  <div className="card-body">
                    <h2 className="card-title">JPTR lets you work.</h2>
                    <p className="card-description text-white">
                      JPTR’s boards, lists, and cards enable you to organize and prioritize your projects in a fun, flexible and rewarding way.
                    </p>
                    <label className="badge badge-neutral">JPTR</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="about-team team-4">
          <div className="container">
            <div className="row">
              <div className="col-md-8 ml-auto mr-auto text-center">
                <h2 className="title">We are nerd rockstars</h2>
                <h4 className="description">This is the paragraph where you can write more details about your team. Keep you user engaged by providing meaningful information.</h4>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-6 col-lg-7 ml-auto mr-auto">
                <div className="card card-profile card-plain">
                  <div className="row">
                    <div className="col-md-5">
                      <div className="card-image">
                        <a href="#pablo">
                          <img className="img img-raised rounded" src="../assets/img/olivia.jpg" />
                        </a>
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="card-body">
                        <h4 className="card-title">Ella Evelyn</h4>
                        <h6 className="category">Air Crew Member</h6>
                        <p className="card-description">
                          Think in the morning. Act in the noon. Eat in the evening. Sleep in the night......
                        </p>
                        <div className="card-footer">
                          <a href="#pablo" className="btn btn-icon btn-neutral btn-twitter"><i className="fa fa-twitter"></i></a>
                          <a href="#pablo" className="btn btn-icon btn-neutral btn-facebook"><i className="fa fa-facebook-square"></i></a>
                          <a href="#pablo" className="btn btn-icon btn-neutral btn-google"><i className="fa fa-google"></i></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-7 ml-auto mr-auto">
                <div className="card card-profile card-plain">
                  <div className="row">
                    <div className="col-md-5">
                      <div className="card-image">
                        <a href="#pablo">
                          <img className="img img-raised rounded" src="../assets/img/emily.jpg" />
                        </a>
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="card-body">
                        <h4 className="card-title">Mila Skylar</h4>
                        <h6 className="category">Architect</h6>
                        <p className="card-description">
                          Love cures people - both the ones who give it and the ones who receive it...
                        </p>
                        <div className="card-footer">
                          <a href="#pablo" className="btn btn-icon btn-neutral btn-linkedin"><i className="fa fa-linkedin"></i></a>
                          <a href="#pablo" className="btn btn-icon btn-neutral btn-facebook"><i className="fa fa-facebook-square"></i></a>
                          <a href="#pablo" className="btn btn-icon btn-neutral btn-dribbble"><i className="fa fa-dribbble"></i></a>
                          <a href="#pablo" className="btn btn-icon btn-neutral btn-google"><i className="fa fa-google"></i></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-7 ml-auto mr-auto">
                <div className="card card-profile card-plain">
                  <div className="row">
                    <div className="col-md-5">
                      <div className="card-image">
                        <a href="#pablo">
                          <img className="img img-raised rounded" src="../assets/img/james.jpg" />
                        </a>
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="card-body">
                        <h4 className="card-title">Daniel Carter</h4>
                        <h6 className="category">Aviation Inspector</h6>
                        <p className="card-description">
                          Keep your face always toward the sunshine - and shadows will fall behind you...
                        </p>
                        <div className="card-footer">
                          <a href="#pablo" className="btn btn-icon btn-neutral btn-youtube"><i className="fa fa-youtube-play"></i></a>
                          <a href="#pablo" className="btn btn-icon btn-neutral btn-twitter"><i className="fa fa-twitter"></i></a>
                          <a href="#pablo" className="btn btn-icon btn-neutral btn-instagram"><i className="fa fa-instagram"></i></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-7 ml-auto mr-auto">
                <div className="card card-profile card-plain">
                  <div className="row">
                    <div className="col-md-5">
                      <div className="card-image">
                        <a href="#pablo">
                          <img className="img img-raised rounded" src="../assets/img/michael.jpg" />
                        </a>
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="card-body">
                        <h4 className="card-title">Dylan Wyatt</h4>
                        <h6 className="category">Conservation Scientist</h6>
                        <p className="card-description">
                          There is only one corner of the universe you can be certain of improving, and that's your own self...
                        </p>
                        <div className="card-footer">
                          <a href="#pablo" className="btn btn-icon btn-neutral btn-linkedin"><i className="fa fa-linkedin"></i></a>
                          <a href="#pablo" className="btn btn-icon btn-neutral btn-facebook"><i className="fa fa-facebook-square"></i></a>
                          <a href="#pablo" className="btn btn-icon btn-neutral btn-google"><i className="fa fa-google"></i></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="about-office">
          <div className="container">
            <div className="row text-center">
              <div className="col-md-8 ml-auto mr-auto">
                <h2 className="title">Our office is our second home</h2>
                <h4 className="description">Here are some pictures from our office. You can see the place looks like a palace and is fully equiped with everything you need to get the job done.</h4>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <img className="rounded img-raised" alt="Raised Image" src="../assets/img/bg27.jpg" />
              </div>
              <div className="col-md-4">
                <img className="rounded img-raised" alt="Raised Image" src="../assets/img/bg24.jpg" />
              </div>
              <div className="col-md-4">
                <img className="rounded img-raised" alt="Raised Image" src="../assets/img/bg28.jpg" />
              </div>
              <div className="col-md-6">
                <img className="rounded img-raised" alt="Raised Image" src="../assets/img/project6.jpg" />
              </div>
              <div className="col-md-6">
                <img className="rounded img-raised" alt="Raised Image" src="../assets/img/project14.jpg" />
              </div>
            </div>
          </div>
        </div> */}
        <div className="about-contact">
          <div className="container">
            <div className="row">
              <div className="col-md-8 mr-auto ml-auto">
                <h2 className="text-center title">Want to work with us?</h2>
                <h4 className="text-center description">Divide details about your product or agency work into parts. Write a few lines about each one and contact us about any further collaboration. We will get back to you in a couple of hours.</h4>
                <form className="contact-form">
                  {/* <div className="row">
                    <div className="col-md-4">
                      <label>First name</label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text"><i className="now-ui-icons users_circle-08"></i></span>
                        </div>
                        <input type="text" className="form-control" placeholder="First Name..." />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <label>Your email</label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text"><i className="now-ui-icons ui-1_email-85"></i></span>
                        </div>
                        <input type="text" className="form-control" placeholder="Email Name..." />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="control-label">Speciality</label>
                        <select className="selectpicker" data-size="7" data-style="btn btn-default btn-round mt-0" title="Speciality">
                          <option value="1">I'm a Designer</option>
                          <option value="2">I'm a Developer</option>
                          <option value="3">I'm a Hero</option>
                        </select>
                      </div>
                    </div>
                  </div> */}
                  <div className="row">
                    <div className="col-md-4 ml-auto mr-auto text-center">
                      <a href='tel:+19519418905' className="btn btn-primary btn-round mt-4 btn-lg">
                        +1 (951) 941-8905
                      </a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="footer ">
        <div className="container">
          {/* <nav>
            <ul>
              <li>
                <a href="#creative">
                  Creative Tim
                </a>
              </li>
              <li>
                <a href="#creative">
                  About Us
                </a>
              </li>
              <li>
                <a href="#creative">
                  Blog
                </a>
              </li>
              <li>
                <a href="#creative">
                  License
                </a>
              </li>
            </ul>
          </nav> */}
          <div className="copyright">
            &copy;
            <script>
              document.write(new Date().getFullYear())
            </script>, Designed by 
            <a href="http://www.jptr.io">JPTR, Corp.</a>. Coded by
            <a href="https://www.treesnclouds.com">Trees N Clouds, Inc.</a>.
          </div>
        </div>
      </footer>
    </div></div>

  );
}

export default App;